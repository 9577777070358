<template>
  <g>
    <line-item
      v-if="xIndex !== 0"
      :nodeparent="row.nodeparent"
      :topPadding="topPadding"
      :leftPadding="leftPadding"
      :blockWidth="blockWidth * 0.55"
      :blockHeight="blockHeight"
      :parentTopCoords="parentTopCoords"
      :item="row"
      :rootColor="rootColor"
      :simpleArrows="simple"
      :getBg="getBg"
      :updateSelectedArrows="updateSelectedArrows"
      :updatePreviewArrows="updatePreviewArrows"
      :isSelected="isSelected"
      :hovered="hovered"
      :getCoordsFromDict="getCoordsFromDict"
      :blockGap="blockGap"
    />
    <block-item
      :isAnchor="isAnchor"
      :blockWidth="blockWidth * 0.75"
      :blockHeight="blockHeight"
      :topPadding="topPadding"
      :leftPadding="leftPadding"
      :item="row"
      :onClick="onClick"
      :handleSelectOpening="handleSelectOpening"
      :setTooltip="tooltipEmitter"
      :isSelected="isSelected"
      :mode="mode"
      :rootColor="rootColor"
      :key="row.nodethis"
      :simpleBlock="simple"
      :getBg="getBg"
      :customPadding="customPadding"
      :getCoordsFromDict="getCoordsFromDict"
      :setCoordsToDict="setCoordsToDict"
      :userPreferences="userPreferences"
      :onContextClick="onContextClick"
      :onDblClick="onDblClick"
      :blockGap="blockGap"
    />
  </g>
</template>

<script>
import BlockItem from './BlockItem.vue';
import LineItem from './LineItem.vue';

export default {
  props: [
    'xIndex',
    'row',
    'topPadding',
    'leftPadding',
    'blockWidth',
    'blockHeight',
    'parentTopCoords',
    'rootColor',
    'simple',
    'getBg',
    'updateSelectedArrows',
    'updatePreviewArrows',
    'isSelected',
    'isAnchor',
    'onClick',
    'handleSelectOpening',
    'setTooltip',
    'mode',
    'customPadding',
    'setCoordsToDict',
    'getCoordsFromDict',
    'userPreferences',
    'onContextClick',
    'onDblClick',
    'blockGap'
  ],
  data: () => ({
    hovered: false,
  }),
  components: {
    LineItem,
    BlockItem,
  },

  methods: {
    tooltipEmitter(v) {
      if (v === null) {
        this.hovered = false;
      }

      if (v) {
        this.hovered = true;
      } else {
        this.hovered = false;
      }

      this.setTooltip(v);
    },
  },
};
</script>

<style scoped></style>
