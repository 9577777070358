<template>
  <g>
    <path
      fill="none"
      :style="{ strokeWidth: getThickness }"
      :d="pathString"
      :stroke="item.ancestor ? '#9A9A9A' : getColor"
      marker-end="url(#simpleArrowEnd)"
    />
    <!-- <polygon
      fill="white"
      :style="{ strokeWidth: getThickness }"
      :points="getSimpleArrowString"
    /> -->

    <g v-if="backArrows">
      <g
        v-for="(backArrow, index) in backArrows"
        :key="`backArrow-${index}-${item.nodethis}`"
      >
        <path
          v-if="!backArrow.reversed"
          fill="none"
          style="strokewidth: 1; opacity: 0.7"
          :d="backArrow.lineStr"
          stroke="#9A9A9A"
          marker-end="url(#simpleArrowEnd)"
        />
        <path
          v-if="backArrow.reversed"
          fill="none"
          style="strokewidth: 1; opacity: 0.7"
          :d="backArrow.lineStr"
          stroke="blue"
          marker-start="url(#simpleArrowEnd)"
        />
      </g>
    </g>

    <text
      :x="leftPadding - blockWidth + borderRadius * 2 - 4"
      :y="topPadding + borderRadius * 3 - 4"
      font-size="14px"
      fill="white"
      v-if="!simpleArrows"
      style="userselect: none"
    >
      {{ `${getMoveText}` }}
    </text>
    <!-- <text
      :x="leftPadding - blockWidth + borderRadius * 5"
      :y="topPadding + borderRadius * 3"
         font-size="14px"
    >
      {{
        `${item.movenum} ${item.move_text}`
      }}
    </text> -->

    <text
      :x="leftPadding - blockWidth + borderRadius * 2 - 4"
      :y="topPadding + borderRadius * 3 + borderRadius * 3"
      font-size="14px"
      fill="white"
      v-if="!simpleArrows"
      style="userselect: none"
    >
      {{ item.move_count_hero || '' }}
    </text>
    <!-- :fill="getColor" -->
    <g v-if="!simpleArrows">
      <polygon
        :fill="getColor"
        :style="{ strokeWidth: item.tr_ar_thickness }"
        :points="getArrowString"
        v-if="item.tr_ar_head === 'good'"
      />

      <circle
        :fill="'white'"
        :style="{ strokeWidth: item.tr_ar_thickness }"
        :stroke="getColor"
        :cx="leftPadding - borderRadius * 2.5"
        :cy="topPadding + blockHeight / 2"
        :r="borderRadius / 1.5"
        v-if="item.tr_ar_head === 'passably'"
      />
      <g v-if="item.tr_ar_head === 'bad'">
        <!-- <path fill="none" :style="{strokeWidth: getThickness}" :d="`M${leftPadding - borderRadius*2},${topPadding + (blockHeight/2)} h${borderRadius}`" :stroke="getColor"/> -->
        <path
          fill="none"
          :style="{ strokeWidth: item.tr_ar_thickness }"
          :d="`M${leftPadding - borderRadius * 3},${
            topPadding + blockHeight / 2 - borderRadius
          } v${borderRadius * 2}`"
          :stroke="getColor"
        />
        <path
          fill="none"
          :style="{ strokeWidth: item.tr_ar_thickness }"
          :d="`M${leftPadding - borderRadius * 2},${
            topPadding + blockHeight / 2 - borderRadius
          } v${borderRadius * 2}`"
          :stroke="getColor"
        />
      </g>
    </g>
  </g>
</template>

<script>
import { mapState } from 'vuex';
export default {
  props: [
    'nodeparent',
    'topPadding',
    'leftPadding',
    'blockWidth',
    'blockHeight',
    'parentTopCoords',
    'item',
    'rootColor',
    'simpleArrows',
    'getBg',
    'isSelected',
    'updateSelectedArrows',
    'updatePreviewArrows',
    'hovered',
    'getCoordsFromDict',
    'blockGap',
  ],
  data: () => ({
    parentCoords: null,
    borderRadius: 8,
    backArrows: null,
  }),
  computed: {
    ...mapState('ui', ['userPreferences']),
    getMoveText() {
      const { lang_pieces } = this.userPreferences;
      const move_lang = this.item?.move_lang || '';
      if (move_lang && move_lang[lang_pieces]) {
        return move_lang[lang_pieces];
      }
      return move_lang[0] || '';
    },
    fixedHm() {
      return this.item?.hm;
    },
    getThickness() {
      if (this.simpleArrows) {
        return 1;
      }
      return this.item.tr_arrow_thickness;
      // return Math.ceil(this.item.b_arrow_thickness / 5) || 2
    },
    getColor() {
      if (this.getBg) {
        return this.getBg(this.item.nodethis);
      }

      if (this.simpleArrows) {
        return 'black';
      }
      return this.item.b_arrow_color || 'black';
    },
    getSimpleArrowString() {
      const { topPadding, leftPadding, blockHeight, borderRadius } = this;
      const halfBlock = blockHeight / 2;
      return `
      ${leftPadding - borderRadius * 3 + 6},${topPadding + halfBlock + 6}
      ${leftPadding - borderRadius * 4 + 14},${topPadding + halfBlock / 2 + 8} 
      ${leftPadding - borderRadius},${topPadding + halfBlock}
       ${leftPadding - borderRadius * 4 + 14},${
        topPadding + halfBlock * 1.5 - 8
      } `;
    },
    getArrowString() {
      const { topPadding, leftPadding, blockHeight, borderRadius } = this;
      const halfBlock = blockHeight / 2;
      return `
      ${leftPadding - borderRadius * 3},${topPadding + halfBlock}
      ${leftPadding - borderRadius * 4},${topPadding + halfBlock / 2 + 8} 
      ${leftPadding - borderRadius - 2},${topPadding + halfBlock}
       ${leftPadding - borderRadius * 4},${topPadding + halfBlock * 1.5 - 8} `;
    },
    getSquareString() {
      const { topPadding, leftPadding, blockHeight, borderRadius } = this;
      const halfBlock = blockHeight / 2;
      const halfBorder = borderRadius / 2;
      return `
      
      ${leftPadding - halfBorder * 3},${topPadding + halfBlock - halfBorder} 
      ${leftPadding - halfBorder * 4},${topPadding + halfBlock}
      ${leftPadding - halfBorder * 3},${topPadding + halfBlock + halfBorder} 
      ${leftPadding - halfBorder * 2},${topPadding + halfBlock}
      `;
    },
    pathString() {
      const {
        getParentX,
        fixedParentTopPosition,
        blockWidth,
        parentTopCoords,
        borderRadius,
        topPadding,
        blockHeight,
        getTopPosition,
      } = this;

      if (parentTopCoords === getTopPosition)
        return `M${getParentX - 6},${fixedParentTopPosition} h${
          this.simpleArrows ? 6 : blockWidth - borderRadius * 3
        } `;
      if (this.simpleArrows) {
        return `M${
          getParentX - (this.simpleArrows ? borderRadius : 0)
        },${fixedParentTopPosition} 
    h2
    a${borderRadius / 2},${borderRadius / 2} 0 0 1 ${borderRadius / 2},${
          borderRadius / 2
        }
    v${topPadding - fixedParentTopPosition + blockHeight / 2 - borderRadius * 2 + 6}
    a-${borderRadius / 2},-${borderRadius / 2} 1 0 0 ${borderRadius / 2},${
          borderRadius / 2
        }
    h0
    `;
      }

      return `M${getParentX + borderRadius / 2 - 4},${fixedParentTopPosition} 

    a${borderRadius},${borderRadius} 0 0 1 ${borderRadius},${borderRadius}
    v${topPadding - fixedParentTopPosition + blockHeight / 2 - borderRadius * 2}
    a-${borderRadius},-${borderRadius} 1 0 0 ${borderRadius},${borderRadius}
    h${blockWidth / 4 + borderRadius}
    `;
    },
    fixedParentTopPosition() {
      const { parentTopCoords, blockHeight } = this;

      return parentTopCoords + blockHeight / 2;
    },
    getParentX() {
      const { leftPadding, blockWidth, blockGap } = this;
      if (blockGap) {
        const actual = leftPadding - 8;
        return actual < 0 ? 0 : actual;
      }
      return leftPadding - blockWidth < 0 ? 0 : leftPadding - blockWidth;
    },
    halfXPoint() {
      const { parentCoords, leftPadding, blockWidth } = this;

      if (!parentCoords) return 0;

      return leftPadding - 8 - (parentCoords[0] - 8) / 2;
    },
    getTopPosition() {
      const { topPadding, parentCoords, blockHeight } = this;
      return this.topPadding;
      //   if(!parentCoords) return topPadding
      //   const fixedTopPosition = parentCoords[1] - (blockHeight / 2)
      //   return fixedTopPosition > topPadding ? fixedTopPosition : topPadding
    },
  },
  methods: {
    getSometimesArrow(sometimesId) {
      if (this.item.allSometimesPresent) {
        return null;
      }

      const dataCoords = this.getCoordsFromDict(sometimesId);
      // const target = document.getElementById(`${'block' + sometimesId}`);

      if (dataCoords) {
        // const dataCoords = JSON.parse(target.dataset.cornerCoords);
        //0 - left, 1 - top

        const currentY = this.topPadding;
        const currentX = this.getParentX + this.blockWidth / 2;

        const [parentX, parentY] = dataCoords;

        const onSameY = currentY - parentY === 0;
        const onSameX = currentX - (parentX + this.blockWidth / 2) === 0;

        const { leftPadding, borderRadius, topPadding, blockHeight } = this;
        const { getParentX, fixedParentTopPosition, blockWidth } = this;
        const halfBlock = blockHeight / 2;
        //тупо обратная стрелка, т.к. прямой родитель в sometimes
        if (onSameY && onSameX) {
          return null;
        }

        const diffX = parentX - currentX;
        // если больше 0 то идёт сверху родителя вправо и заходит справа от блока
        // если меньше - то идёт снизу родителя влево и заходит слева блока

        if (onSameY && !onSameX) {
          // надо понять, какое между ними расстояние
          return null;
          //       const isRightBehind = diffX === this.blockWidth * 2;
          //       // //console.log(isRightBehind)

          //       if (isRightBehind) {
          //         return {
          //           lineStr: `M${
          //             getParentX - borderRadius
          //           },${fixedParentTopPosition} h${blockWidth - 10} `,
          //           reversed: true,
          //         };
          //       } else {
          //         if (parentX > currentX) {
          //           //console.log('правее');

          //           // правее
          //           return {
          //             lineStr: `M${leftPadding + blockWidth / 2},${
          //               topPadding + blockHeight / 2 + 10
          //             }
          //                     a-${borderRadius},-${borderRadius} 1 0 0 ${borderRadius},${borderRadius}
          //                     h${blockWidth / 2}
          //                     a${borderRadius},${borderRadius} 0 0 1 ${borderRadius},${borderRadius}
          //                     v${blockWidth / 4 + borderRadius}
          // `,
          //             reversed: false,
          //           };
          //         } else {
          //           //console.log('левее');
          //         }
          //         return {
          //           lineStr: `M
          //           ${leftPadding + borderRadius * 3 + 6},${
          //             topPadding + halfBlock + 6
          //           }
          //           ${leftPadding - borderRadius * 4 + 14},${
          //             topPadding + halfBlock / 2 + 8
          //           }
          //           ${leftPadding - borderRadius + 40},${topPadding + halfBlock}
          //           ${parentX},${parentY}`,
          //           reversed: false,
          //         };
          //       }
        }
        // return null;

        const diffY = parentY - currentY;
        // если больше 0 то идёт сверху родителя и заходит снизу блока
        // если меньше - то идёт снизу родителя и заходит сверху блока

        if (!onSameY && onSameX) {
          //console.log('sameX');
          return null;
        }

        if (!onSameY && !onSameX) {
          if (diffX > 0) {
            // правее

            if (diffY > 0) {
              // console.log('правее и выше');
              // console.log(this.item.nodethis, sometimesId);

              //  if (this.item.nodethis == 36109 && sometimesId == 8513) {
              //   console.log((parentY) + blockHeight * 2 + borderRadius / 2 )
              return {
                lineStr: `M${parentX - blockWidth},${
                  parentY + blockHeight / 4 - 1
                } 
                        a-${borderRadius},-${borderRadius} 1 0 0 ${borderRadius},${borderRadius}
                        h${blockWidth / 2 + borderRadius}
                        a${borderRadius},-${borderRadius} 0 0 0 ${borderRadius},-${borderRadius}
                        v-${diffY - borderRadius / 2}
                        a${borderRadius},${borderRadius} 0 0 0 -${borderRadius},-${borderRadius}
                        h-${diffX - blockWidth - borderRadius}
                        a${borderRadius},${borderRadius} 0 0 0 -${borderRadius},${
                  borderRadius / 2
                }


    `,
                reversed: false,
              };
              // }
            } else {
              // ниже
              //console.log('правее и ниже');
              //console.log(this.item.nodethis, sometimesId);

              if (diffY === -10) {
                return {
                  lineStr: `M${parentX - blockWidth},${
                    parentY + blockHeight / 4 - 1
                  }
                           v8
                                  // a-${borderRadius},-${borderRadius} 1 0 0 ${borderRadius},${borderRadius}
                                  // h${blockWidth / 2 + borderRadius}
                                  // a${borderRadius},${borderRadius} 0 0 1 ${borderRadius},${borderRadius}
                                  // v${
                                    diffY * -1 -
                                    blockHeight / 2 -
                                    borderRadius / 2
                                  }
                                  // a${borderRadius},${borderRadius} 0 0 1 -${borderRadius},${borderRadius}
                                  // h-${diffX - blockWidth - borderRadius}
                                  // a${borderRadius},${borderRadius} 0 0 0 -${borderRadius},${
                    borderRadius / 2
                  }

              `,
                  reversed: false,
                };
              }
              // if(this.item.nodethis === 1721633) {
              //    console.log('diffY', diffY)
              //    if(diffY === -10) {
              //       return null
              //    }

              // }
              return {
                lineStr: `M${parentX - blockWidth},${
                  parentY + blockHeight / 4 - 1
                }
                                  a-${borderRadius},-${borderRadius} 1 0 0 ${borderRadius},${borderRadius}
                                  h${blockWidth / 2 + borderRadius}
                                  a${borderRadius},${borderRadius} 0 0 1 ${borderRadius},${borderRadius}
                                  v${
                                    diffY * -1 -
                                    blockHeight / 2 -
                                    borderRadius / 2
                                  }
                                  a${borderRadius},${borderRadius} 0 0 1 -${borderRadius},${borderRadius}
                                  h-${diffX - blockWidth - borderRadius}
                                  a${borderRadius},${borderRadius} 0 0 0 -${borderRadius},${
                  borderRadius / 2
                }

              `,
                reversed: false,
              };
            }
          } else {
            // левее
            if (diffY > 0) {
              //console.log('левее и выше');
              // выше
            } else {
              if (diffY === -10) {
                return {
                  lineStr: `M${parentX - blockWidth},${
                    parentY + blockHeight / 4 + 1
                  }
                                  a-${borderRadius / 2},${
                    borderRadius / 2
                  } 1 0 0 ${borderRadius / 2},${borderRadius / 2 - 1}
                                  h${blockWidth / 2 + borderRadius}
                  
                                  h${diffX * -1 + blockWidth - 25}
                                    a${borderRadius / 2},${
                    borderRadius / 2
                  } 0 0 1 ${borderRadius / 2},${borderRadius / 2}

              `,
                  reversed: false,
                };
              }

              return {
                lineStr: `M${parentX - blockWidth + 14},${
                  parentY + blockHeight / 4 - 1
                }
                                  a-${borderRadius / 2},${
                  borderRadius / 2
                } 1 0 0 ${borderRadius / 2},${borderRadius / 2}
                                  h${blockWidth / 2 + 4}
                                  a${borderRadius / 2},${
                  borderRadius / 2
                } 0 0 1 ${borderRadius / 2},${borderRadius / 2}
                                  v${diffY * -1 - blockHeight / 2 + 10}
                                 a-${borderRadius / 2},-${
                  borderRadius / 2
                } 1 0 0 ${borderRadius / 2},${borderRadius / 2}
                                  h${diffX * -1}
                                    a${borderRadius / 2},${
                  borderRadius / 2
                } 0 0 1 ${borderRadius / 2},${borderRadius / 2}

              `,
                reversed: false,
              };
              //console.log('левее и ниже');
              // ниже
            }
          }
        }
      }
    },
    async updateBackArrows(isSelected) {
      await this.$nextTick();

      if (this.item.ancestor) {
        this.backArrows = null;

        const coords = this.getCoordsFromDict(this.nodeparent);

        // const parentItem = document.getElementById(this.nodeparent);
        // const coords = parentItem?.dataset.cornerCoords;

        this.parentCoords = coords || null;
        let result = null;
        const allSometimes = this.item.ancestor.sometimes.map((i) => i);
        if (this.item.sometimesParent) {
          allSometimes.push('null');
        }
        for (let ancestorId of allSometimes) {
          const backArrow = this.getSometimesArrow(ancestorId);
          if (backArrow) {
            if (!result) {
              result = [];
            }
            result.push(backArrow);
          }
        }
        //console.log('arrows', result);
        if (isSelected) {
          this.updateSelectedArrows(result);
          this.backArrows = result;
        } else {
          this.backArrows = result;
        }
      }
    },
    async updatePreviewBackArrows(isSelected) {
      await this.$nextTick();

      if (this.item.ancestor) {
        const coords = this.getCoordsFromDict(this.nodeparent);

        // const parentItem = document.getElementById(this.nodeparent);
        // const coords = parentItem?.dataset.cornerCoords;

        this.parentCoords = coords || null;
        let result = null;
        const allSometimes = this.item.ancestor.sometimes.map((i) => i);
        if (this.item.sometimesParent) {
          allSometimes.push('null');
        }
        for (let ancestorId of allSometimes) {
          const backArrow = this.getSometimesArrow(ancestorId);
          if (backArrow) {
            if (!result) {
              result = [];
            }
            result.push(backArrow);
          }
        }

        if (isSelected) {
          this.updatePreviewArrows(result);
        } else {
          this.updatePreviewArrows(null);
        }
      }
    },
  },
  // async mounted() {
  //   // await new Promise(res => setTimeout(res,1000))
  //   this.updateBackArrows()
  // },
  watch: {
    isSelected: {
      immediate: true,
      handler: function (val, oldVal) {
        if (val !== oldVal) {
          this.updateBackArrows(val);
        }
      },
    },
    hovered: {
      immediate: true,
      handler: function (val, oldVal) {
        if (val !== oldVal) {
          this.updatePreviewBackArrows(val);
        }
      },
    },
  },
};
</script>

<style scoped></style>
