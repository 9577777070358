<template>
  <div style="height: 300px">
    <v-table density="compact" class="bg-grey pr-4">
      <thead class="text-caption" style="width: 100%">
        <tr>
          <td width="10%">
            <v-tooltip :text="$t('opening1.mistakesEcoHint')">
              <template v-slot:activator="{ props }">
                <span v-bind="props">{{ $t('opening1.mistakesEco') }}</span>
              </template>
            </v-tooltip>
          </td>
          <td width="13%">
            <v-tooltip :text="$t('opening1.mistakesdEvalHint')">
              <template v-slot:activator="{ props }">
                <span v-bind="props">{{ $t('opening1.mistakesdEval') }}</span>
              </template>
            </v-tooltip>
          </td>
          <td width="20%">
            <v-tooltip :text="$t('opening1.mistakesNumHint')">
              <template v-slot:activator="{ props }">
                <span v-bind="props">{{ $t('opening1.mistakesNum') }}</span>
              </template>
            </v-tooltip>
          </td>
          <td width="15%">
            <v-tooltip :text="$t('opening1.mistakesTotalShortageHint')">
              <template v-slot:activator="{ props }">
                <span v-bind="props">{{
                  $t('opening1.mistakesTotalShortage')
                }}</span>
              </template>
            </v-tooltip>
          </td>
          <td width="15%">
            {{ $t('opening1.mistakesStudied') }}
          </td>
          <td width="15%">
            <v-tooltip :text="$t('opening1.mistakesReFailHint')">
              <template v-slot:activator="{ props }">
                <span v-bind="props">{{ $t('opening1.mistakesReFail') }}</span>
              </template>
            </v-tooltip>
          </td>
          <td width="12%"><v-icon v-if="!deletable">mdi-eye</v-icon></td>
        </tr>
      </thead>
    </v-table>
    <v-virtual-scroll :height="252" :items="mistakesOpenings" ref="vScroll">
      <template v-slot:default="{ item }">
        <v-table density="compact" width="100%" ref="mistakeTable">
          <tbody>
            <tr
              :key="item.position_id + '' + item.eco_common + '' + item.labels"
              :class="`${isActive(item) ? 'bg-light-grey' : ''} text-caption`"
              @click="handleMistakeClick(item)"
              style="cursor: pointer; width: 100%"
            >
              <td :style="{ color: getTextColor(item.mdegree) }" width="10%">
                <v-tooltip :text="item.eco_opening">
                  <template v-slot:activator="{ props }">
                    <span v-bind="props">{{ item.eco_common }}</span>
                  </template>
                </v-tooltip>
              </td>
              <td
                :style="{ color: getTextColor(item.mdegree) }"
                width="13%"
                @mouseenter="
                  (e) =>
                    toggleTooltip({
                      top: e.clientY - 280,
                      left: 50 - 500,
                      move_uci: item.prevmove,
                      fen: item.fen,
                      prevmove: item.prevmove,
                      herowhite: item.herowhite,
                    })
                "
                @mouseleave="toggleTooltip(null)"
              >
                {{
                  (
                    Math.round(item.total_shortage / (item.total_count || 1)) /
                    100
                  ).toFixed(2)
                }}
              </td>

              <td
                :style="{ color: getTextColor(item.mdegree) }"
                width="20%"
                @mouseenter="
                  (e) =>
                    toggleTooltip({
                      top: e.clientY - 280,
                      left: 50 - 500,
                      move_uci: item.move_uci,
                      fen: item.fen,
                      herowhite: item.herowhite,
                    })
                "
                @mouseleave="toggleTooltip(null)"
              >
                {{ item.total_count }} ({{ item.unreasonable_count }})
              </td>

              <td
                :style="{ color: getTextColor(item.mdegree) }"
                width="15%"
                @mouseenter="
                  (e) =>
                    toggleTooltip({
                      top: e.clientY - 280,
                      left: 50 - 500,
                      move_uci: item.move_uci,
                      fen: item.fen,
                      herowhite: item.herowhite,
                    })
                "
                @mouseleave="toggleTooltip(null)"
              >
                {{ (item.total_shortage / 100).toFixed(2) }}
              </td>

              <td width="15%">
                <v-icon size="small">{{ getStatusIcon(item.studied) }} </v-icon>
              </td>

              <td width="15%">
                {{ getRefailIcon(item.studied, item.mdegree) }}
              </td>
              <td
                width="12%"
                @click="
                  (e) => {
                    e.stopPropagation();
                    handleEyeClick(item);
                  }
                "
                v-if="!deletable"
              >
                <v-icon size="small" :color="getEyeColor(item.labels)">
                  {{ getEyeIcon(item.labels) }}
                </v-icon>
              </td>
              <td
                width="12%"
                @click="
                  (e) => {
                    e.stopPropagation();
                    handleDeleteClick(item);
                  }
                "
                v-else
              >
                <v-icon size="small" color="white"> mdi-delete </v-icon>
              </td>
            </tr>
          </tbody>
        </v-table>
      </template>
    </v-virtual-scroll>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';

export default {
  props: {
    toggleTooltip: {
      type: Function,
      defailt: () => null,
    },
    exercisesList: {
      default: () => undefined,
    },
    deletable: {
      default: false,
      type: Boolean,
    },
  },
  data: () => ({
    preventFromClick: false,
  }),
  computed: {
    ...mapGetters('data', ['getMistakesOpenings']),
    ...mapState('data', [
      'selectedMistake',
      'compareFilters',
      'selectedOpening',
      'heroesList',
    ]),
    mistakesOpenings() {
      const openings = this.getMistakesOpenings;
      const exers = this.exercisesList;
      if (exers === undefined) {
        return openings;
      }
      if (exers.length === 0) {
        return [];
      }
      const filtered = openings.filter((op) =>
        exers.find(
          (ex) =>
            ex.position_id === op.position_id && ex.herowhite === op.herowhite,
        ),
      );
      return filtered;
    },
    posid() {
      return this.$route.params?.posid;
    },
    isActive() {
      const selectedMistake = this.selectedMistake;
      const posid = this.posid;
      return (item) => {
        if (!this.deletable) {
          const fen = item.fen;
          if (!selectedMistake) return false;
          return selectedMistake.fen == fen;
        } else {
          if (!posid) return false;
          return posid == item.position_id;
        }
      };
    },
    heroid() {
      return this.$route.params.heroid;
    },
    heroCategories() {
      return this.$route.params.categories;
    },
  },
  methods: {
    ...mapActions('data', [
      'getOpenings',
      'loadChildren',
      'getMistakesOpening',
      'modifyPositionLabel',
      'getHeroesList',
      'removeFromExercises',
    ]),
    handleDeleteClick(item) {
      this.removeFromExercises({
        heroid: Number(this.heroid),
        roots: [
          {
            posid: item.position_id,
            herowhite: item.herowhite,
          },
        ],
      });
    },
    getStatusIcon(val) {
      switch (val) {
        case 1:
          return 'mdi-check';
        case 0:
          return 'mdi-close';
      }
      return 'mdi-questionmark';
    },
    getEyeColor(labels) {
      if (!labels) return 'grey';
      const has3 = labels.indexOf(3) > -1;
      const has2 = labels.indexOf(2) > -1;

      if (has2 || has3) return 'white';
      return 'grey';
    },
    getEyeIcon(labels) {
      if (!labels || labels.indexOf(2) > -1) {
        return 'mdi-eye';
      }
      return 'mdi-eye-off';
    },
    handleMistakeClick(item) {
      this.preventFromClick = true;
      if (this.exercisesList?.length > 0) {
        const occur = this.exercisesList?.find(
          (ex) =>
            ex.position_id === item.position_id &&
            ex.herowhite === item.herowhite,
        );
        if (occur) {
          this.$emit('mistakeClick', occur, item);
          return;
        }
      }
      this.$emit('mistakeClick', item);
    },
    handleGoToMistake(item) {
      const mistakesOpenings = this.mistakesOpenings;

      const index = mistakesOpenings.findIndex(
        (m) => m.position_id === item.position_id,
      );

      const scrollEl = this.$refs.vScroll;
      if (scrollEl && index > -1) {
        scrollEl.scrollToIndex(index);
      }
    },
    handleEyeClick(item) {
      const labelsArray = this.$parseArray(item.labels);
      const heroid = this.heroid;
      if (labelsArray.indexOf(2) > -1) {
        this.modifyPositionLabel({
          opening: item,
          scenario: 'toIgnore',
          heroid,
          herocats: this.heroCategories,
        });
        return;
      }

      if (labelsArray.indexOf(3) > -1) {
        this.modifyPositionLabel({
          opening: item,
          scenario: 'toDefault',
          heroid,
          herocats: this.heroCategories,
        });
        return;
      }

      this.modifyPositionLabel({
        opening: item,
        scenario: 'toWatchlist',
        heroid,
        herocats: this.heroCategories,
      });
    },
    getRefailIcon(studied, mdegree) {
      return studied * mdegree > 1 ? '!' : '-';
    },
    getTextColor(mdegree) {
      const mapper = {
        0: 'green',
        1: 'black',
        2: 'red',
      };
      return mapper[mdegree];
    },
  },
  watch: {
    selectedMistake: {
      immediate: true,
      handler: function (val) {
        if (!val) return;
        if (this.preventFromClick) {
          this.preventFromClick = false;
          return;
        } else {
          this.handleGoToMistake(val);
        }
      },
    },
    mistakesOpenings: {
      immediate: true,
      handler: async function (val) {
        if (val) {
          const selectedMistake = this.selectedMistake;
          if (selectedMistake) {
            this.handleGoToMistake(selectedMistake);
          }
        }
      },
    },
  },
  mounted() {
    // console.log('mounted')
    const selectedMistake = this.selectedMistake;
    if (selectedMistake) {
      this.handleGoToMistake(selectedMistake);
    }
  },
};
</script>

<style scoped></style>
