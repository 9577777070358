<template>
  <g>
    <g
      @mouseenter="handleOverBlock"
      @mouseleave="setTooltip(null)"
      @click="handleBlockClick"
      @contextmenu="handleContextClick"
    >
      <!-- :data-cornerCoords="
          JSON.stringify([
            leftPadding + blockWidth,
            getTopPosition + blockHeight / 2,
          ])
        " -->
      <path
        style="filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.2))"
        :id="'block' + item.nodethis"
        :d="`M${leftPadding},${getTopPosition + padding} h${
          blockWidth - borderRadius * 2
        } a${borderRadius},${borderRadius} 0 0 1 ${borderRadius},${borderRadius}
          v${
            blockHeight - padding * 2 - borderRadius * 2
          } a${borderRadius},${borderRadius} 0 0 1 -${borderRadius},${borderRadius}
          h-${
            blockWidth - borderRadius * 2
          } a${borderRadius},${borderRadius} 0 0 1 -${borderRadius},-${borderRadius}
          v-${
            blockHeight - padding * 2 - borderRadius * 2
          } a${borderRadius},${borderRadius} 0 0 1 ${borderRadius},-${borderRadius}
          z`"
        :class="{
          active_block: isSelected,
          hasOpening: !!item.correspondingMistake,
        }"
        :style="{
          strokeWidth: item.node_thickness,
          stroke: mode === 2 ? 'rgb(231,231,231)' : item.node_bordercolor,
        }"
        :fill="getDropShadow"
      />
      <!-- :fill="getDropShadow" -->
      <!-- :fill="item.nodethis === 2388180  || item.nodethis === 2367222    ? 'green' :  getDropShadow" -->

      <!-- :fill="getDropShadow" -->
      <!-- filter: `drop-shadow(0px 0px 6px ${getDropShadow})`, -->
      <text
        style="user-select: none"
        :x="leftPadding "
        :y="getTopPosition + padding + 14"
        font-size="12px"
        fill="white"
      >
        {{ item.ev_text }}
        <!-- {{ item.pos_to_id }}, {{item.hm}} -->
        <!-- {{item.nodethis}} -->
      </text>
      <text
        style="user-select: none"
        v-if="simpleBlock && item?.l_need !== undefined"
        :x="leftPadding "
        :y="getTopPosition + blockHeight / 2 + 6"
        font-size="12px"
        fill="white"
      >
        <!-- {{ getMoveText }} -->
        {{ item?.l_need }} | {{ getMoveText }}
        <!-- {{ item.pos_to_id }}, {{item.hm}} -->
        <!-- {{item.nodethis}} -->
      </text>
      <text
        style="user-select: none"
        v-if="simpleBlock && item?.l_need === undefined"
        :x="leftPadding "
        :y="getTopPosition + blockHeight / 2 + 6"
        font-size="12px"
        fill="white"
      >
        <!-- {{ getMoveText }} -->
        {{
          (
            Math.round(item.total_shortage / (item.total_count || 1)) / 100
          ).toFixed(2)
        }}
        <!-- {{ item.pos_to_id }}, {{item.hm}} -->
        <!-- {{item.depth}} -->
        <!-- {{item.nodethis}} -->
      </text>
      <bar-chart
        :blockHeight="blockHeight"
        :blockWidth="blockWidth"
        :leftPadding="leftPadding - 4"
        :topPadding="topPadding"
        :results_groups="item.results_groups"
        :result_hero="item.results_hero"
        :block="item"
        :blockId="item.nodethis"
        :mode="mode"
        :rootColor="rootColor"
        v-if="!simpleBlock"
      />
    </g>
    <node-toggler
      v-if="item.iscrown === 0 && !simpleBlock"
      :borderRadius="7"
      :top="getTopPosition + blockHeight / 2"
      :left="leftPadding + blockWidth + 5"
      :status="statusState"
      @click="onClick(item)"
    />
  </g>
</template>

<script>
import NodeToggler from './NodeToggler.vue';
import BarChart from './BarChart.vue';
// import { mapState } from 'vuex';

export default {
  inject: ['openedNodesArr'],
  props: [
    'userPreferences',
    'isAnchor',
    'item',
    'blockWidth',
    'blockHeight',
    'topPadding',
    'leftPadding',
    'onClick',
    'onContextClick',
    'setTooltip',
    'handleSelectOpening',
    'isSelected',
    'mode',
    'rootColor',
    'simpleBlock',
    'getBg',
    'customPadding',
    'setCoordsToDict',
    'getCoordsFromDict',
    'onDblClick',
    'blockGap'
  ],
  data: () => ({
    borderRadius: 2,
    defaulPadding: 10,
    timeoutId: null,
  }),
  components: {
    NodeToggler,
    BarChart,
  },
  computed: {
    // ...mapState('ui', ['userPreferences']),
    padding() {
      return this.customPadding || this.defaulPadding;
    },
    getMoveText() {
      if (!this.userPreferences) return;

      const { lang_pieces } = this.userPreferences;
      const move_lang = this.item?.move_lang || [];
      if (!move_lang) {
        return 'Root';
      }
      if (this.item.hideText === true) return '';
      if (move_lang && move_lang[lang_pieces]) {
        return move_lang[lang_pieces];
      }
      return move_lang[0] || '';
    },
    getEvText() {
      return (key) => this.$t(`path.to.smth.${key}`);
    },
    getDropShadow() {
      const item = this.item;
      if (this.getBg) {
        return this.getBg(item.nodethis, item.hm, item);
      }

      if (!item || !item.showBg) {
        return 'rgba(255,255,255,0.4)';
      }

      // mlabels
      // если 4 и ход героя - показываем
      // есть 5 и ход поля - показываем
      // если 6 и ход героя- не показываем
      // если 7 и ход поля - не показываем

      // default_training
      // если 0 - не показываем, если 1 - показываем
      const { status, default_training } = item;

      if (status === 1) {
        return 'rgba(255,255,255,0.4)';
      }

      if (this.rootColor === 'black') {
        if (item.hm % 2 === 0) {
          if (status === 0 || (status === 2 && default_training === 1)) {
            return 'rgba(	52, 170, 220, .2)';
          }

          return 'rgba(255,255,255,0.4)';
        } else {
          if (status === 0 || (status === 2 && default_training === 1)) {
            return 'rgba(	33, 230, 79, .4 )';
          }

          return 'rgba(255,255,255,0.4)';
        }
      } else {
        if (item.hm % 2 !== 0) {
          if (status === 0 || (status === 2 && default_training === 1)) {
            return 'rgba(	52, 170, 220, .2)';
          }

          return 'rgba(255,255,255,0.4)';
        } else {
          if (status === 0 || (status === 2 && default_training === 1)) {
            return 'rgba(	33, 230, 79, .2 )';
          }

          return 'rgba(255,255,255,0.4)';
        }
      }

      // console.log(item.numdec_hero > 0)

      // const mapper = [
      //   'rgba(255,255,255,0.4)',
      //   'rgba(	52, 170, 220, .2)',
      //   'rgba(134, 138, 105, .3)',
      //   'rgba(	33, 230, 79, .2 )',
      // ];

      // return mapper[item.node_backlight] || 'rgba(255,255,255,0.4)';
    },
    getTopPosition() {
      return this.topPadding;
      // const { topPadding, parentCoords,blockHeight } = this;
      // if(!parentCoords) return topPadding
      // const fixedTopPosition = parentCoords[1] - (blockHeight / 2)
      // return fixedTopPosition > topPadding ? fixedTopPosition : topPadding
      // return parentCoords ? parentCoords[1] - (blockHeight / 2) : topPadding
    },
    statusState() {
      const openedNodesArr = this.openedNodesArr();

      return (
        openedNodesArr.findIndex((i) => i.nodethis === this.item.nodethis) > -1
      );
    },
  },
  methods: {
    handleOverBlock() {
      this.setTooltip({
        top: this.topPadding - 200,
        left: this.leftPadding + this.blockWidth / 2 - this.borderRadius * 3,
        fen: this.item.fen,
        move_uci: this.item.move_uci,
        arrowColor: this.item.b_arrow_color || 'black',
        tree: true,
        herowhite: this.rootColor === 'black' ? 0 : 1,
      });
    },
    handleBlockClick(e) {
      if (!this.timeoutId) {
        this.timeoutId = setTimeout(() => {
          this.handleSelectOpening(this.item, e);
          this.timeoutId = null
        }, 500); //tolerance in ms
      } else {
        clearTimeout(this.timeoutId);
        this.timeoutId = null;
        if (this.onDblClick) {
          this.onDblClick(this.item);
        }
      }
    },
    handleContextClick(e) {
      e.preventDefault();
      if (this.onContextClick && this.item?.l_node !== 'Root') {
        this.onContextClick({ item: this.item, e });
      }
      // const heroid = this.$route.params.heroid
      // this.$router.push({
      //   name: "ExercisePage",
      //   params: {
      //     heroid ,
      //     posid: Number(this.item.id),
      //     categories: this.$route.params.categories,
      //     herowhite: 0
      //   },
      // });
    },
  },
  mounted() {
    this.setCoordsToDict({
      nodethis: this.item.nodethis,
      coords: [
        this.leftPadding + this.blockWidth,
        this.getTopPosition + this.blockHeight / 2,
      ],
    });
  },
};
</script>

<style scoped>
.active_block {
  stroke: white !important;
  stroke-dasharray: 4px;
  stroke-dashoffset: 8px;
  animation: stroke 0.2s linear infinite;
}

.hasOpening {
  filter: drop-shadow(3px 5px 2px rgb(184, 30, 30, 1)) !important;
}

@keyframes stroke {
  to {
    stroke-dashoffset: 0;
  }
}
</style>
