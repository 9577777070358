<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :view-box.camel="`-${blockWidth / 2} -20 ${getWidth} ${getHeight}`"
    :width="getWidth + 120"
    :height="getHeight"
    id="svg-tree"
  >
    <defs>
      <marker
        id="simpleArrowEnd"
        viewBox="0 0 10 10"
        refX="1"
        refY="5"
        markerUnits="strokeWidth"
        markerWidth="10"
        markerHeight="10"
        orient="auto"
      >
        <path d="M 0 2 L 6 5 L 0 8 z" fill="#9A9A9A" />
      </marker>
      <marker
        id="simpleArrowEndBlue"
        viewBox="0 0 10 10"
        refX="1"
        refY="5"
        markerUnits="strokeWidth"
        markerWidth="10"
        markerHeight="10"
        orient="auto"
      >
        <path d="M 0 2 L 6 5 L 0 8 z" fill="#34aadc" />
      </marker>
    </defs>

    <g v-for="(column, xIndex) in items" :key="xIndex + 'col'">
      <text
        :x="getPaddingLeft(xIndex) - (blockWidth * 0.75) / 2"
        :y="getContainerHeight * i - getContainerHeight / 2"
        v-for="i in getTextCount"
        :key="i + 'grd'"
        fill="rgba(55,55,55,.3)"
        :style="{ fontSize: `${blockHeight}px`, userSelect: 'none' }"
      >
        {{ column[0] && column[0].movenum }}
      </text>
      <rect
        :x="getPaddingLeft(xIndex) -( blockGap ? 0 : (blockWidth * 0.5))"
        :y="0"
        :width="blockGap || (blockWidth + (blockWidth * 0.75) / 2)"
        :height="getHeight"
        :fill="getColumnFill(column)"
        v-if="!hideMoveZones"
      />

      <BlockGroup
        v-for="(row, yIndex) in column"
        :key="xIndex + 'col' + yIndex + 'row' + row.nodeid"
        :xIndex="xIndex"
        :topPadding="getPaddingTop(yIndex, column, items, xIndex)"
        :leftPadding="getPaddingLeft(xIndex)"
        :blockWidth="blockWidth"
        :blockHeight="blockHeight"
        :parentTopCoords="getParentCoords(yIndex, column, items, xIndex)"
        :item="updatedItem(row)"
        :rootColor="rootColor"
        :simple="simple"
        :getBg="getBg"
        :updateSelectedArrows="updateSelectedArrows"
        :updatePreviewArrows="updatePreviewArrows"
        :isSelected="selectedOpening?.nodethis === row.nodethis"
        :isAnchor="yIndex < 2"
        :customPadding="customPadding"
        :onClick="loadChildren"
        :handleSelectOpening="handleSelectOpening"
        :setTooltip="setTooltip"
        :mode="mode"
        :row="row"
        :getCoordsFromDict="getCoordsFromDict"
        :setCoordsToDict="setCoordsToDict"
        :userPreferences="userPreferences"
        :onContextClick="onContextClick"
        :onDblClick="onDblClick"
        :blockGap="blockGap"
      >
        <!-- <line-item
          v-if="xIndex !== 0"
          :topPadding="getPaddingTop(yIndex, column, items, xIndex)"
          :leftPadding="getPaddingLeft(xIndex)"
          :blockWidth="blockWidth * 0.55"
          :blockHeight="blockHeight"
          :parentTopCoords="getParentCoords(yIndex, column, items, xIndex)"
          :item="row"
          :rootColor="rootColor"
          :simpleArrows="simple"
          :getBg="getBg"
          :updateSelectedArrows="updateSelectedArrows"
          :isSelected="selectedOpening?.nodethis === row.nodethis"
        />
        <block-item
          :isAnchor="yIndex < 2"
          :blockWidth="blockWidth * 0.75"
          :blockHeight="blockHeight"
          :topPadding="getPaddingTop(yIndex, column, items, xIndex)"
          :leftPadding="getPaddingLeft(xIndex)"
          :item="updatedItem(row)"
          :onClick="loadChildren"
          :handleSelectOpening="handleSelectOpening"
          :setTooltip="setTooltip"
          :isSelected="selectedOpening?.nodethis === row.nodethis"
          :mode="mode"
          :rootColor="rootColor"
          :key="row.nodethis"
          :simpleBlock="simple"
          :getBg="getBg"
          :customPadding="customPadding"
        /> -->
      </BlockGroup>
      <g v-if="selectedBackArrows">
        <g
          v-for="(backArrow, index) in selectedBackArrows"
          :key="`backArrow-${index}`"
        >
          <path
            v-if="!backArrow.reversed"
            fill="none"
            style="strokewidth: 8; opacity: 1"
            :d="backArrow.lineStr"
            stroke="#34aadc"
            marker-end="url(#simpleArrowEndBlue)"
          />
          <path
            v-if="backArrow.reversed"
            fill="none"
            style="strokewidth: 8; opacity: 1"
            :d="backArrow.lineStr"
            stroke="#34aadc"
            marker-start="url(#simpleArrowEndBlue)"
          />
        </g>
      </g>
      <g v-if="previewBackArrows">
        <g
          v-for="(backArrow, index) in previewBackArrows"
          :key="`previewArrow-${index}`"
        >
          <path
            v-if="!backArrow.reversed"
            fill="none"
            style="strokewidth: 2; opacity: 1"
            :d="backArrow.lineStr"
            stroke="#34aadc"
            marker-end="url(#simpleArrowEndBlue)"
          />
          <path
            v-if="backArrow.reversed"
            fill="none"
            style="strokewidth: 2; opacity: 1"
            :d="backArrow.lineStr"
            stroke="#34aadc"
            marker-start="url(#simpleArrowEndBlue)"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
import BlockGroup from './BlockGroup.vue';
import { mapState } from 'vuex';

export default {
  props: [
    'movesModel',
    'mode',
    'selectedOpening',
    'items',
    'getPaddingTop',
    'loadChildren',
    'blockWidth',
    'blockHeight',
    'getParentCoords',
    'setTooltip',
    'handleSelectOpening',
    'handleArrowPress',
    'rootColor',
    'simple',
    'getBg',
    'size',
    'mistakeFens',
    'customPadding',
    'hideMoveZones',
    'onContextClick',
    'onDblClick',
    'blockGap',
  ],
  data: () => ({
    selectedBackArrows: null,
    previewBackArrows: null,
    // size: 900,
  }),
  components: {
    // BlockItem,
    // LineItem,
    BlockGroup,
  },
  computed: {
    ...mapState('ui', ['userPreferences']),
    getContainerHeight() {
      const vh = this.$store?.state?.sizes?.vh || 0;

      const actHeight = vh * 0.9 - 70;
      return actHeight;
    },
    getWidth() {
      const { blockWidth, items, size } = this;
      const itemsWidth = items.length * (blockWidth + blockWidth * 0.5);
      return itemsWidth > size ? itemsWidth : size;
    },
    getHeight() {
      const { items, blockHeight } = this;
      const actHeight = this.getContainerHeight;
      // console.log('actHeight',actHeight)

      if (!items || items.length === 0 || !items[0][0]) return 0;

      const heightByItems =
        items[0][0].visibleChildren * blockHeight +
        blockHeight * (this.simple ? 0 : 4);
      // - (!this.simple ? 0 : ((items[0][0].visibleChildren - items[0][0].children.length - 1) * blockHeight));
      // console.log('heightByItems', heightByItems)
      const res = heightByItems > actHeight ? heightByItems : actHeight;

      return res;
    },
    getTextCount() {
      const actHeight = this.getContainerHeight;
      const colHeight = this.getHeight;

      return Math.floor(colHeight / actHeight);
    },
  },
  methods: {
    setCoordsToDict({ nodethis, coords }) {
      this.nodeDict[nodethis] = coords;
    },
    getCoordsFromDict(nodethis) {
      return this.nodeDict[nodethis];
    },
    updateSelectedArrows(val) {
      this.selectedBackArrows = val;
    },
    updatePreviewArrows(val) {
      this.previewBackArrows = val;
    },
    updatedItem(item) {
      if (!this.mistakeFens) {
        return item;
      } else {
        const correspondingMistake = this.mistakeFens[item.pos_to_id];
        if (correspondingMistake) {
          return { ...item, correspondingMistake };
        }
        return item;
      }
    },
    getColumnFill(items) {
      if (!items || !items.length === 0) return 'rgba(255,255,255,.1)';

      return items[0]?.hm % 2 === 0
        ? 'rgba(255,255,255,.1)'
        : 'rgba(50,50,50,.1)';
    },
    getPaddingLeft(index) {
      const { blockWidth } = this;
      return (this.blockGap || (blockWidth + blockWidth * 0.3)) * index;
    },
    handleKeydown(event) {
      switch (event.keyCode) {
        case 37:
          event.preventDefault();
          this.handleArrowPress('left');
          break;
        case 39:
          event.preventDefault();
          this.handleArrowPress('right');
          break;
        case 38:
          event.preventDefault();
          this.handleArrowPress('up');
          break;
        case 40:
          event.preventDefault();
          this.handleArrowPress('down');
          break;
      }
    },
  },
  beforeMount() {
    this.nodeDict = {};
    window.addEventListener('keydown', this.handleKeydown, null);
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.handleKeydown);
  },
};
</script>

<style>
#svg-tree {
  scroll-behavior: smooth !important;
}
</style>
