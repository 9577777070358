<template>
  <g>
    <defs>
      <linearGradient
        :id="blockId + 'results_groups'"
        x1="0%"
        y1="0%"
        x2="100%"
        y2="0%"
      >
        <stop
          v-for="({ number, color }, index) in getColors(resultGroupsObject, true)"
          :key="blockId + index + 'resultGroupsObject'"
          :offset="number + '%'"
          :style="`stop-color: ${color};`"
        />
      </linearGradient>
      <linearGradient
        :id="blockId + 'result_hero'"
        x1="0%"
        y1="0%"
        x2="100%"
        y2="0%"
      >
        <stop
          v-for="({ number, color }, index) in getColors(resultHeroObject)"
          :key="blockId + index + 'resultHeroObject'"
          :offset="number + '%'"
          :style="`stop-color: ${color};`"
        />
      </linearGradient>
    </defs>
    <g
      v-if="
        (mode === 1 &&
        getColors(resultHeroObject) &&
        getColors(resultHeroObject).length > 0 && resultHeroObject.totalCount) || (mode === 2 && heroDecisionsColors)
      "
    >
      <text
        :x="leftPadding + blockWidth / 3 - 20"
        :y="padding + topPadding + borderRadius * 2 + 7"
        font-size="9px"
         fill="white"
          style="user-select: none"
      >
        {{
          resultHeroObject && (resultHeroObject.totalCount > 999 ? "∞" : resultHeroObject.totalCount)
        }} 
      </text>

      <rect
        :x="leftPadding + blockWidth / 3"
        :y="padding + topPadding + borderRadius * 2"
        :width="50"
        :height="borderRadius"
        :fill="`url(#${blockId}result_hero)`"
        stroke="black"
      />
    </g>

    <g v-if="(mode===1 && resultGroupsObject && resultGroupsObject.totalCount) || (mode === 2 && groupDecisionColors)">
       <text
        :x="leftPadding + blockWidth / 3 - 20"
        :y="padding + topPadding + borderRadius *
            (
            getColors(resultGroupsObject, true) &&
            getColors(resultGroupsObject, true).length > 0
             && resultGroupsObject && resultGroupsObject.totalCount
              ? 3
              : 2) + 7"
        font-size="9px"
         fill="white"
          style="user-select: none"
      >
        {{
          resultGroupsObject &&( resultGroupsObject.totalCount > 999 ? "∞" : resultGroupsObject.totalCount)
        }}
      </text>

      <rect
        :x="leftPadding + blockWidth / 3"
        :y="
          padding +
          topPadding +
          borderRadius *
            (
            getColors(resultGroupsObject, true) &&
            getColors(resultGroupsObject, true).length > 0
             && resultGroupsObject &&  resultGroupsObject.totalCount
              ? 3
              : 2)
        "
        :width="50"
        :height="borderRadius"
        :fill="`url(#${blockId}results_groups)`"
        stroke="black"
      />
    </g>
  </g>
</template>

<script>
export default {
  props: [
    "results_groups",
    "result_hero",
    "blockHeight",
    "blockWidth",
    "leftPadding",
    "topPadding",
    "blockId",
    "block",
    "mode",
    "rootColor"
  ],
  data: () => ({
    padding: 14,
    borderRadius: 8,
  }),
  computed: {
    getColors() {
      const mode = this.mode;

      return (object, isGroup) => {
        if (mode === 1) {
          return this.$getFill(object, true);
        } else {
          if(isGroup) {
          return this.groupDecisionColors;

          } else {
            return this.heroDecisionsColors
          }
        }
      };
    },
    resultGroupsObject() {
      const results_groups = this.results_groups;
      return this.getGroupsObject(results_groups);
    },
    resultHeroObject() {
      const result_hero = this.result_hero;
      return this.getGroupsObject(result_hero);
    },
    groupDecisionColors() {
      const { children, id, gooddec_groups } = this.block;
      const greenCount = gooddec_groups 
      // || children.find(
      //   (item) => item.pos_from_id === id
      // )?.parentdecisions_groups;

      if (greenCount === undefined) return null;
      if (greenCount === null) return null;

      if (greenCount === 0) {
        return [
          {
            color: "#ff8080",
            number: 100,
          },
        ];
      }
      if (greenCount === 100) {
        return [
          {
            color: "#80ff80",
            number: 100,
          },
        ];
      }
      const colorsArr = ["#80ff80", "#ff8080"];
      const values = [greenCount || 0, 100 - (greenCount || 0)];
      const reduced = values.reduce((acc, item, index) => {
        if (!item) return acc;
        acc.push({ number: item, color: colorsArr[index] });
        if (index < values.length - 1) {
          acc.push({ number: item, color: colorsArr[index + 1] });
        }
        return acc;
      }, []);


      if(reduced.length === 0) return null

      return reduced
      //  const greenCount = children.find(item => item.pos_from_id === id)?.parentdecisions_hero
      // const redCount = this.$getMoveQualityFill(
      //   moveCount,
      //   this.$store.state.data.deviation,
      //   waste
      // );
      // if (greenCount === undefined) return null;

      // return [
      //   {
      //     color: "#80ff80",
      //     number: (greenCount || 0),
      //   },
      //   {
      //     color: "#ff8080",
      //     number: 100 - (greenCount || 0),
      //   },
      // ];
    },
    heroDecisionsColors() {
         const { children, id, gooddec_hero, hm } = this.block;
      const greenCount = gooddec_hero
      
      
      if(hm % 2 === 0 && this.rootColor !== 'black') return null
      if(hm % 2 !== 0 && this.rootColor !== 'white') return null

      // || children.find(
      //   (item) => item.pos_from_id === id
      // )?.parentdecisions_hero

      if (greenCount === undefined) return null;
      if (greenCount === null) return null;

      if (greenCount === 0) {
        return [
          {
            color: "#ff8080",
            number: 100,
          },
        ];
      }
      if (greenCount === 100) {
        return [
          {
            color: "#80ff80",
            number: 100,
          },
        ];
      }
      const colorsArr = ["#80ff80", "#ff8080"];
      const values = [greenCount || 0, 100 - (greenCount || 0)];
      const reduced = values.reduce((acc, item, index) => {
        if (!item) return acc;
        acc.push({ number: item, color: colorsArr[index] });
        if (index < values.length - 1) {
          acc.push({ number: item, color: colorsArr[index + 1] });
        }
        return acc;
      }, []);


      if(reduced.length === 0) return null

      return reduced
    }
  },
  methods: {
    getGroupsObject(value) {
      if (!value) return null;
  
      const totalCount = value.reduce((acc, num) => (acc += num), 0);
      if (totalCount === 0) return null;
      return {
        totalCount,
        array: value,
      };
    },
  },
};
</script>

<style scoped></style>
