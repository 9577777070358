<template>
  <div class="d-flex">
    <v-card
      v-if="tooltip"
      height="200px"
      width="200px"
      :style="`position: absolute;top:${tooltip.top}px; bottom:${tooltip.bottom}px;  left:${tooltip.left}px; z-index: 5000`"
    >
      <advanced-board
        :size="`100%`"
        :config="{
          viewOnly: true,
          fen: tooltip && tooltip.fen,
          lastMove: getLastMoveArray(tooltip),
          orientation: tooltip && tooltip.herowhite == 0 ? 'black' : 'white',
        }"
        :key="'tooltip'"
      />
    </v-card>

    <div
      :style="{
        height: '80vh',
        flex: 1,
      }"
    >
      <div style="width: 500px">
        <h3>{{ $t('opening1.weak') }}</h3>
        <AnalysisFilters />
        <MistakesList
          @mistakeClick="handleMistakeClick"
          :toggleTooltip="toggleTooltip"
        />
      </div>
    </div>
    <div style="flex: 2">
      <!-- <div v-if="treeNodes">
        <div v-for="node in treeNodes" :key="node.position_id">
          {{ node.position_id }} - {{ node.ancestor }}
        </div>
      </div> -->
      <!-- {{ treeNodes }} -->

      <div
        style="
          overflow: auto;
          width: 100%;
          height: calc(90vh - 17px);
          padding-top: 10px;
        "
        ref="treeContainer"
      >
        <svg-tree
          v-if="treeNodes"
          :items="getFlatArray"
          :getPaddingTop="getPaddingTop"
          :loadChildren="() => {}"
          :blockHeight="blockHeight"
          :blockWidth="blockWidth"
          :getParentCoords="getParentCoords"
          :setTooltip="toggleTooltip"
          :handleSelectOpening="handleBlockClick"
          :selectedOpening="selectedBlock"
          :mode="1"
          :movesModel="0"
          :handleArrowPress="() => {}"
          :rootColor="sideOptionsFilter === 2 ? 'w' : 'b'"
          :simple="true"
          :getBg="getBg"
          :size="500"
          :customPadding="14"
          :hideMoveZones="true"
          :key="heroid + treeKey"
          :blockGap="blockWidth - 6"
          ref="tree"
        />

        <!-- :height="blockHeight * 3" -->
      </div>
    </div>
    <div style="flex: 1">
      <div
        :style="{
          height: Math.floor(sizes.vw * 0.18) + 40 + 'px',
          width: Math.floor(sizes.vw * 0.18) + 'px',
          paddingLeft: '14px',
        }"
      >
        <advanced-board
          :key="sideOptionsFilter + treeKey"
          :size="Math.floor(sizes.vw * 0.18) + 'px'"
          :config="{
            viewOnly: true,
            fen: selectedMistake && selectedMistake.fen,
            orientation: sideOptionsFilter === 2 ? 'white' : 'black',
            drawable: {
              brushes: [],
            },
            lastMove: getLastMove,
          }"
          :arrows="[]"
          :allMoves="[]"
          :currentMoveIndex="undefined"
          :movePieces="false"
          ref="bigBoard"
          @customMove="() => {}"
        />
      </div>

      <div :style="{ width: Math.floor(sizes.vw * 0.18) + 'px' }">
        <v-table v-if="selectedMistake" density="compact">
          <template v-slot:default>
            <tbody>
              <tr>
                <td>{{ $t('opening1.mistakesdEval') }}</td>
                <td style="font-weight: bold; text-align: right">
                  {{
                    (
                      Math.round(
                        selectedMistake.total_shortage /
                          (selectedMistake.total_count || 1),
                      ) / 100
                    ).toFixed(2)
                  }}
                </td>
              </tr>
              <tr>
                <td>{{ $t('opening1.mistakesNum') }}</td>
                <td style="font-weight: bold; text-align: right">
                  {{ selectedMistake.total_count }}
                </td>
              </tr>
              <tr>
                <td>{{ $t('opening1.mistakesTotalShortage') }}</td>
                <td style="font-weight: bold; text-align: right">
                  {{ (selectedMistake.total_shortage / 100).toFixed(2) }}
                </td>
              </tr>
              <tr>
                <td>{{ $t('opening1.mistakesStudied') }}</td>
                <td style="font-weight: bold; text-align: right">
                  {{
                    selectedMistake.studied === 1
                      ? $t('opening1.studiedYes')
                      : $t('opening1.studiedNo')
                  }}
                </td>
              </tr>
              <tr>
                <td>{{ $t('opening1.mistakesReFail') }}</td>
                <td style="font-weight: bold; text-align: right">
                  {{
                    selectedMistake.studied * selectedMistake.mdegree > 1
                      ? $t('opening1.studiedYes')
                      : $t('opening1.studiedNo')
                  }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-table>
        <v-btn color="blue" @click="goToAnalysis" :disabled="!selectedMistake"
          >Go to 3</v-btn
        >
        <!-- {{ selectedMistake }} -->
      </div>
    </div>
  </div>
</template>

<script>
import AdvancedBoard from '@/components/AdvancedBoard';
import SvgTree from '@/components/SvgTree';

import AnalysisFilters from '@/components/AnalysisFilters';
import MistakesList from '@/components/MistakesList';
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import { Chess } from 'chess.js';
import deepObjectSearch from '@/helpers/deepObjectSearch';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

export default {
  data: () => ({
    showDialog: false,
    currentFen: null,
    searchModel: '',
    treeNodes: null,
    blockHeight: 54,
    blockWidth: 80,
    tooltip: null,
    treeKey: Math.random(),
  }),
  components: {
    AnalysisFilters,
    AdvancedBoard,
    SvgTree,
    MistakesList,
  },
  computed: {
    ...mapGetters('data', ['getMistakesOpenings']),
    ...mapState(['isAuthenticated', 'sizes']),
    ...mapState('data', [
      'sideOptionsFilter',
      'openingsTree',
      'selectedMistake',
      'compareFilters',
      'selectedOpening',
      'heroesList',
      'currentSide',
      'parsedFen',
      'mistakes',
    ]),
    ...mapState('ui', ['userPreferences']),
    selectedBlock() {
      const selectedMistake = this.selectedMistake;
      if (!selectedMistake) {
        return null;
      }
      return { nodethis: selectedMistake.position_id };
    },
    getFlatArray() {
      const tree = this.treeNodes;

      if (!tree) return [];
      const reducer =
        (iteration, parentIndex = 0) =>
        (acc, item, index) => {
          if (!acc[iteration]) {
            acc[iteration] = [];
          }

          acc[iteration].push({
            ...item,
            parentIndex: parentIndex,
            visible: true,
          });

          if (!item.children) {
            item.children = [];
          } else {
            item.children.reduce(reducer(iteration + 1, index), acc);
          }

          return acc;
        };

      const result = tree.children.reduce(reducer(1, 0), [[tree]]);

      return result;
    },
    getLastMove() {
      return [];
    },
    heroid() {
      return this.$route.params.heroid;
    },
    heroCategories() {
      return this.$route.params.categories;
    },
    getBg() {
      return (nodethis, hm, item) => {
        const evaluation = (
          Math.round(item.total_shortage / (item.total_count || 1)) / 100
        ).toFixed(2);

        function getColor(value) {
          value = Math.max(0, Math.min(1, value));
          const r = Math.round(55 + value * (255 - 55));
          const g = 55;
          const b = 55;

          return `rgb(${r}, ${g}, ${b})`;
        }

        return getColor(Number(evaluation));
        // if (selectedNodes.findIndex((i) => i.nodethis === nodethis) > -1) {
        //   return 'rgba(255,155,155,1)';
        // }
        // if (
        //   getFullSelectedPath.findIndex((i) => i.nodethis === nodethis) > -1
        // ) {
        //   return 'rgba(255,195,195,0.7)';
        // }
        // //если вражьи ходы
        // if (this.isEnemyMove(hm)) {
        //   return 'rgba(	152, 152, 152, 1)';
        // }

        // if (
        //   getSubTrees?.[currentSubTree].find((i) => i?.nodethis === nodethis)
        // ) {
        //   return 'rgba(	52, 170, 220, .9)';
        // }

        // return 'rgba(	52, 170, 220, .7)';
      };
    },
  },
  methods: {
    ...mapMutations('data', ['SET_DATA_BY_KEY']),
    ...mapActions('data', [
      'getOpenings',
      'loadChildren',
      'getMistakesOpening',
      'modifyPositionLabel',
      'getHeroesList',
      'addToExercises',
      'removeFromExercises',
      'getExercisesList',
      'getMistakes',
    ]),
    ...mapActions(['logOutUser']),
    getLastMoveArray(opening) {
      if (!opening || !opening.prevmove) {
        return;
      }

      return [
        opening.prevmove.substring(0, 2),
        opening.prevmove.substring(2, 4),
      ];
    },
    getParentCoords(yIndex, column, allItems, columnIndex) {
      const parentSlice = allItems[columnIndex - 1];
      let result = 0;
      if (parentSlice) {
        const parentIndex = parentSlice.findIndex(
          (item) => item.nodethis === column[yIndex]?.nodeparent,
        );
        if (parentIndex === -1) return 0;
        const parentTop = this.getPaddingTop(
          parentIndex,
          parentSlice,
          allItems,
          columnIndex - 1,
        );
        result = parentTop;
      }
      return result;
    },
    getPaddingTop(yIndex, column, allItems, columnIndex) {
      //go back and find the parent, then calculate the top padding for it
      const coordsY = this.getParentCoords(
        yIndex,
        column,
        allItems,
        columnIndex,
      );
      let result = coordsY;

      for (let i = 0; i < yIndex; i++) {
        const neightbourItem = column[i];
        if (neightbourItem.nodeparent !== column[yIndex].nodeparent) {
          continue;
        }
        const neightbourCount = neightbourItem.visibleChildren;
        result +=
          (neightbourCount ? neightbourCount : 1) * (this.blockHeight / 2 + 10);
      }

      return result;
    },
    prepareTreeNodes(val) {
      const clone = cloneDeep(val);

      const presentNodes = {};

      // for (let i = 0; i < clone.length; i++) {
      //   const mistake = clone[i];

      //   const {always} = mistake.ancestor

      //   for(let id of always) {
      //      presentNodes[id] = true
      //   }

      //   if(always.length > 0) {
      //     presentNodes[mistake.position_id] = true
      //   }

      // }

      //  for (let i = 0; i < clone.length; i++) {
      //   const mistake = clone[i];

      //   const {sometimes} = mistake.ancestor

      //   const allSometimes = sometimes.reduce((acc,id) => {
      //     if(!presentNodes[id]) {

      //     }
      //   })

      // }

      for (let i = 0; i < clone.length; i++) {
        const mistake = clone[i];

        const key =
          mistake.herowhite === 1 ? 'ancestors_white' : 'ancestors_black';

        const ancestors = this.mistakes[key];

        const currentAncestor = ancestors.find(
          (ancestor) => ancestor.position_id === mistake.position_id,
        );

        if (!currentAncestor) {
          console.log('no ancestor!');
          continue;
        }
        const fixedAlways = currentAncestor.always.filter((u) =>
          clone.find((i) => i.position_id === u),
        );
        const fixedSometimes = currentAncestor.sometimes.filter((u) =>
          clone.find((i) => i.position_id === u),
        );

        mistake.ancestor = {
          always: fixedAlways,
          sometimes: fixedSometimes,
        };
      }

      clone.sort((a, b) => {
        return b.ancestor.always.length - a.ancestor.always.length;
      });

      const tree = {
        children: [],
        nodethis: null,
      };

      const mistakesWithoutParent = [];
      let lastMistakesWithoutParentLength = 0;

      const checkAndAdd = (mistake, nodeparent, depth) => {
        mistake.nodeparent = nodeparent;
        const ancestor = mistake.ancestor;

        if (ancestor.always.length === 0 && ancestor.sometimes.length === 0) {
          if (
            !tree.children.find(
              (item) => item.position_id === mistake.position_id,
            )
          ) {
            presentNodes[mistake.position_id] = true;
            tree.children.push({
              ...mistake,
              nodethis: mistake.position_id,
              children: [],
              depth: 1,
            });
          }
        } else if (ancestor.always.length > 0) {
          for (let u = 0; u < ancestor.always.length; u++) {
            let occurance = deepObjectSearch({
              target: [tree],
              key: 'position_id',
              value: ancestor.always[u],
            })?.[0]?.target;

            if (occurance) {
              if (u === ancestor.always.length - 1) {
                if (
                  !occurance.children.find(
                    (item) => item.position_id === mistake.position_id,
                  )
                ) {
                  presentNodes[mistake.position_id] = true;
                  occurance.children.push({
                    ...mistake,
                    nodethis: mistake.position_id,
                    children: [],
                    skipRight: u,
                    depth: occurance.depth + 1,
                  });
                }
              }
            } else {
              checkAndAdd(
                clone.find((item) => item.position_id === ancestor.always[u]),
                mistake.nodethis,
                u + 1,
              );
              if (u === ancestor.always.length - 1) {
                occurance = deepObjectSearch({
                  target: [tree],
                  key: 'position_id',
                  value: ancestor.always[u],
                })?.[0]?.target;

                if (occurance) {
                  if (
                    !occurance.children.find(
                      (item) => item.position_id === mistake.position_id,
                    )
                  ) {
                    presentNodes[mistake.position_id] = true;
                    occurance.children.push({
                      ...mistake,
                      skipRight: 0,
                      nodethis: mistake.position_id,
                      children: [],
                      depth: occurance.depth + 1,
                    });
                  }
                } else {
                  //   console.log('steel no occurance! ', mistake.position_id);
                  mistakesWithoutParent.push(mistake);
                }
              }
            }
          }
        }
      };

      for (let i = 0; i < clone.length; i++) {
        const mistake = clone[i];
        checkAndAdd(mistake, undefined, 0);
      }

      const iterateMistakesWithoutParent = () => {
        if (lastMistakesWithoutParentLength === mistakesWithoutParent.length) {
          return;
        }
        lastMistakesWithoutParentLength = mistakesWithoutParent.length;
        for (let i = 0; i < mistakesWithoutParent.length; i++) {
          const mistake = mistakesWithoutParent[i];

          const { ancestor } = mistake;

          if (ancestor.sometimes.length === 0) {
            const occur = tree.children.find(
              (n) => n.nodethis === mistake.position_id,
            );
            if (!occur) {
              presentNodes[mistake.position_id] = true;
              tree.children.push({
                ...mistake,
                skipRight: 1,
                nodethis: mistake.position_id,
                children: [],
                allSometimesPresent: false,
                depth: 1,
              });
            }
            continue;
          }
          let lastSometimes, skipRight;

          for (let u = 0; u < ancestor.sometimes.length; u++) {
            lastSometimes = deepObjectSearch({
              target: [tree],
              key: 'position_id',
              value: ancestor.sometimes[u],
            })?.[0]?.target;

            skipRight = u;
          }

          if (!lastSometimes) {
            // console.log('no last sometimes', mistake);
            continue;
          } else {
            if (
              !lastSometimes.children.find(
                (item) => item.position_id === mistake.position_id,
              )
            ) {
              presentNodes[mistake.position_id] = true;
              lastSometimes.children.push({
                ...mistake,
                skipRight: skipRight,
                nodethis: mistake.position_id,
                children: [],
                allSometimesPresent: true,
                depth: lastSometimes.depth + 1,
              });

              mistakesWithoutParent.splice(i, 1);
              i--;
            }
          }
        }

        iterateMistakesWithoutParent();
      };

      iterateMistakesWithoutParent();

      // if (mistakesWithoutParent.length > 0) {
      //   for (let i = 0; i < mistakesWithoutParent.length; i++) {
      //     const mistake = mistakesWithoutParent[i];
      //     const occur = tree.children.find(
      //       (n) => n.nodethis === mistake.position_id,
      //     );
      //     if (!occur) {
      //       tree.children.push({
      //         ...mistake,
      //         skipRight: 1,
      //         nodethis: mistake.position_id,
      //         children: [],
      //         allSometimesPresent: false,
      //         depth: 1,
      //       });
      //     }
      //   }
      // }

      for (let mistake of clone) {
        const occur = presentNodes[mistake.position_id];

        if (!occur) {
          tree.children.push({
            ...mistake,
            skipRight: 1,
            nodethis: mistake.position_id,
            children: [],
            allSometimesPresent: false,
            depth: 1,
          });
        }
      }

      let max = 1000;
      let hasChanges = false;

      const iterateNodesPositions = () => {
        const iterationCycle = () => {
          max--;
          const updateNodePosition = (node) => {
            let childrenToFilter = [];

            const iterator = (item) => {
              const sometimes = item.ancestor.sometimes;

              let farthestSometimes;
              for (let i = 0; i < sometimes.length; i++) {
                const currentSometimes = deepObjectSearch({
                  target: [tree],
                  key: 'nodethis',
                  value: sometimes[i],
                })?.[0]?.target;

                if (currentSometimes) {
                  if (!farthestSometimes) {
                    farthestSometimes = currentSometimes;
                  } else {
                    if (currentSometimes.depth > farthestSometimes.depth) {
                      farthestSometimes = currentSometimes;
                    }
                  }
                }
              }

              if (farthestSometimes && farthestSometimes.depth >= item.depth) {
                childrenToFilter.push(item);
                if (!hasChanges) {
                  hasChanges = true;
                }

                const updateChildrenDepths = (child, newDepth) => {
                  child.depth = newDepth;
                  if (child.children) {
                    for (let u = 0; u < child.children.length; u++) {
                      updateChildrenDepths(child.children[u], newDepth + 1);
                    }
                  }
                };
                updateChildrenDepths(item, farthestSometimes.depth + 1);
                farthestSometimes.children.push({
                  ...item,
                  nodeparent: farthestSometimes.nodethis,
                  sometimesParent: true,
                });
              }
            };

            for (let i = 0; i < node.children.length; i++) {
              iterator(node.children[i]);
            }

            if (childrenToFilter.length > 0) {
              node.children = node.children.reduce((acc, item) => {
                if (
                  childrenToFilter.find((i) => i.nodethis === item.nodethis)
                ) {
                  return acc;
                }
                acc.push(item);
                return acc;
              }, []);
            }
            childrenToFilter = [];

            for (let i = 0; i < node.children.length; i++) {
              updateNodePosition(node.children[i]);
            }
          };
          updateNodePosition(tree, hasChanges);
          // console.log('hasChanges', hasChanges);
          if (hasChanges && max > 0) {
            // console.log('cycle')
            hasChanges = false;
            iterationCycle();
          } else {
            console.log(`hasChanges: ${hasChanges}, max: ${max}`);
          }
        };

        iterationCycle();
      };

      iterateNodesPositions();

      const reducer = () => (acc, item) => {
        acc += item.children?.reduce(reducer(), 0) || 0;
        if (!item.children || item.children.length === 0) acc++;
        return acc;
      };

      const addOpenedChildrenCount = (item, nodeparent) => {
        item.nodeparent = nodeparent;
        item.visibleChildren = item.children?.reduce(reducer(), 0) || 0;
        //  item.parent =  cloneDeep(parent)
        item.children?.forEach((child) =>
          addOpenedChildrenCount(child, item.nodethis),
        );
      };

      addOpenedChildrenCount(tree, tree.nodethis);

      //todo
      const addOffset = (item, index, parent) => {
        const blockWidth = this.blockWidth;

        if (!parent) {
          item.offsetTop = 0;
        } else {
          if (index === 0) {
            item.offsetTop = parent.offsetTop;
          } else {
            // всё сложнее
            // item.offsetTop = parent.offsetTop + (this.blockHeight / 2 + 10 * index)
          }
        }

        item.offsetLeft = item.depth * (blockWidth + blockWidth * 0.3);

        item.children?.forEach((child, index) => addOffset(child, index, item));
      };

      //   console.log('mistakesWithoutParent', mistakesWithoutParent);
      //   console.log(tree);

      //   const iterator = (acc, item) => {
      //     if (
      //       !getMistakesOpenings.find((i) => i.position_id === item.position_id)
      //     ) {
      //       return acc;
      //     }

      //     const fixedAlways = item.always.filter((u) =>
      //       getMistakesOpenings.find((i) => i.position_id === u),
      //     );
      //     const fixedSometimes = item.sometimes.filter((u) =>
      //       getMistakesOpenings.find((i) => i.position_id === u),
      //     );

      //     // if(fixedAlways.length ===0 && fixedSometimes.length === 0) {
      //     //     return acc
      //     // }

      //     acc.push({ ...item, always: fixedAlways, sometimes: fixedSometimes });

      //     return acc;
      //   };

      return tree;
    },
    handleBlockClick(node) {
      const occur = this.getMistakesOpenings.find(
        (i) => i.position_id === node.position_id,
      );

      if (occur) {
        this.SET_DATA_BY_KEY({
          key: 'selectedMistake',
          value: occur,
        });
      }
    },
    async goToNode(node, offset = { top: 0, left: 0 }) {
      const tree = this.$refs.tree;

      if (!tree) {
        return;
      }

      const coords = tree.getCoordsFromDict(node.nodethis);

      if (coords) {
        const container = this.$refs.treeContainer;

        if (container) {
          container.scrollTo({
            left: coords[0] + offset.left,
            top: coords[1] + offset.top,
            behavior: 'smooth',
          });
        }

        // el.scrollIntoView({
        //   behavior: 'smooth',
        //   block: 'end',
        //   inline: 'end',
        // });
      }
    },
    handleMistakeClick(item) {
      this.SET_DATA_BY_KEY({ key: 'selectedMistake', value: item });
      this.goToNode({ nodethis: item.position_id });
    },
    handleSearchDefaultPosition() {},
    handleSearchAfter() {},
    toggleTooltip(coords, absolute = false) {
      if (!coords) {
        this.tooltip = null;
        return;
      }
      const tooltip = this.tooltip;

      if (coords.tree) {
        const treeContainer = this.$refs.treeContainer;

        if (treeContainer) {
          absolute = true;
          const scrollTop = treeContainer.scrollTop;
          const scrollLeft = treeContainer.scrollLeft;

          coords.top += treeContainer.offsetTop;
          coords.left += treeContainer.offsetLeft;
          coords.top -= scrollTop;
          coords.left -= scrollLeft;
        }
      }
      if (coords.top === tooltip?.top && coords.left === tooltip?.left) {
        this.tooltip = null;
        return;
      }

      this.tooltip = { ...coords, left: absolute ? coords?.left : 120 };
    },
    goToAnalysis() {
      this.$router.push({
        name: 'Analysis',
        params: {
          heroid: this.hero_id,
          categories: this.heroCategories,
        },
      });
    },
  },
  mounted() {
    this.sfChess = new Chess();
    if (this.stockFishSync === undefined) {
      const local = localStorage.getItem('useStockfish') === '1';
      this.stockFishSync = local;
    }
    this.getExercisesList({ heroid: Number(this.heroid) });
  },
  beforeUnmount() {
    this.$stockfish?.postMessage('stop');
  },
  watch: {
    userPreferences: {
      immediate: true,
      handler: function (val) {
        if (val) {
          if (!this.heroesList) {
            this.getHeroesList();
          }
          if (this.$stockfish) {
            this.$stockfish.onmessage = this.handleStockFishMessage;
          }

          //   this.getMistakesOpening({
          //     heroid: this.heroid,
          //     herocats: this.heroCategories,
          //   });
        }
      },
    },
    getMistakesOpenings: {
      immediate: true,
      handler: function (val, oldVal) {
        if (!isEqual(val, oldVal)) {
          this.treeKey = Math.random();
          console.time('prepareTree');
          this.treeNodes = this.prepareTreeNodes(val);
          console.timeEnd('prepareTree');

          //   this.SET_DATA_BY_KEY({
          //     key: 'selectedMistake',
          //     value: val[0] || null,
          //   });
        }
      },
    },
    heroid: {
      immediate: true,
      handler: function (val, oldVal) {
        if (val !== oldVal) {
          this.treeNodes = null;
          this.SET_DATA_BY_KEY({
            key: 'mistakes',
            value: null,
          });
          this.SET_DATA_BY_KEY({
            key: 'selectedMistake',
            value: null,
          });
          this.getMistakes({
            heroid: this.heroid,
            herocats: this.heroCategories,
          });
        }
      },
    },
  },
  provide() {
    return {
      openedNodesArr: () => null,
    };
  },
};
</script>

<style scoped></style>
