export default {
  playgroundList: [
    {
      playground_id: 2,
      title: "fide",
    },
    // {
    //   playground_id: 0,
    //   title: "unknown",
    // },
    // {
    //   playground_id: 1,
    //   title: "corr",
    // },
    {
      playground_id: 3,
      title: "lichess.org",
    },
    {
      playground_id: 4,
      title: "chess.com",
    },
  ],
  selectedOpening: null,
  selectedMistake: null,
  openingsTree: null,
  parsedFen: null,
  heroesList: null,
  heroNicknames: {},
  heroNicknamesRaw: {},
  heroStatistics: {},
  mistakesOpenings: [],
  //фильтры
  sideOptionsFilter: 2,
  studiedOptionsFilter: 1,
  watchListOptionsFilter: 1,
  openingOptionsFilter: 1,
  sortByOptionsFilter: 1,
  openSequense: 3,
  compareFilters: {},
  deviation: 40,
  hmRange: [],
  unfinishedExercises: [],
  childrenLoadingProgress: {},
  currentSide: 'white',
  exercisesList: [],
  mistakes: null,
  heroChainParams: null,
  exerciseTree: null,
  autoLoading: false,
  autoLoadingProgress: 0
};
